<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headerType}} {{pageTitle}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="brnadForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >

           <!-- User Name -->
          <validation-provider
            #default="validationContext"
            name="User Name"
            rules=""
          >
            <b-form-group
              label="User Name"
              label-for="user-name"
            >
              <b-form-input
                id="user-name"
                v-model="username"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter User Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- English Name -->
          <validation-provider
            #default="validationContext"
            name="First Name"
            rules="required"
          >
            <b-form-group
              label="First Name"
              label-for="first-name"
            >
              <b-form-input
                id="first-name"
                v-model="first_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter First Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

              <span class="text-danger text-sm" v-if="allerrors.first_name">{{ allerrors.first_name[0] }}</span>

            </b-form-group>
          </validation-provider>

          <!-- Last Name -->
          <validation-provider
            #default="validationContext"
            name="Last Name"
            rules="required"
          >
            <b-form-group
              label="Last Name"
              label-for="last_name"
            >
              <b-form-input
                id="last_name"
                v-model="last_name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Last Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            <span class="text-danger text-sm" v-if="allerrors.last_name">{{ allerrors.last_name[0] }}</span>
            </b-form-group>

          </validation-provider>



          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required|digits:12|integer"
          >
            <b-form-group
              label="phone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Phone"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

              <span class="text-danger text-sm" v-if="allerrors.phone">{{ allerrors.phone[0] }}</span>

            </b-form-group>
          </validation-provider>


           <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="email"
            rules="nullable|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Arabic Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
               <span class="text-danger text-sm" v-if="allerrors.email">{{ allerrors.email[0] }}</span>
            </b-form-group>
          </validation-provider>


          <!-- Address -->
          <validation-provider
            #default="validationContext"
            name="Address"
            rules="required"
          >
            <b-form-group
              label="Address"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="address"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Address"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!--  Gender -->
          <validation-provider
            #default="validationContext"
            name="Select gender"
            rules="required"
          >
            <b-form-group
              label="Select Gender"
              label-for="gender"
              :state="getValidationState(validationContext)"
            >
               <v-select
                  id="gender"
                  v-model="gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="types"
                  :reduce="types => types.key"
                />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.gender">{{ allerrors.gernder[0] }}</span>
            </b-form-group>
          </validation-provider>


          <!-- image -->

          <div  >
            <b-media class="mb-2">
              <template #aside >
                <b-avatar
                  v-if="imageUrl"
                  ref="previewEl"
                  :src="imageUrl"
                  :text="avatarText(name)"
                  size="90px"
                  rounded
                />
              </template>
            </b-media>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                @click="$refs.imageRef.click()"
              >
                <input
                  ref="imageRef"
                  type="file"
                  class="d-none"
                  @change="onImageChange"
                >
                <span class="d-none d-sm-inline">Upload image</span>
                <feather-icon
                  icon="EditIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
              <span class="text-danger text-sm" v-if="allerrors.captains_image">{{ allerrors.captains_image[0] }}</span>
            </div>
            <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>

          </validation-provider> -->
          </div>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }} 
            </b-button>
            <!-- spinner -->
            <b-button
              v-if="loading"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BCardText, BMedia, BAvatar, BSpinner,  BRow, BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { watch } from '@vue/composition-api'
import DataService from './data/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    vSelect,
    BRow, 
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    avatarText,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSlider: {
      type: Object,
      default: () => {},
    },
    companyType: {
      type: [Array, Object],
      required: true
    },
    pageTitle: String,
  },
  data() {
    return {
      loading: false,
      required,
      alphaNum,
      email,
      headerType: 'Add',
      username:'',
      first_name:'',
      last_name:'',
      phone:'',
      email:'',
      address:'',
      captains_image: '',
      imageUrl: '',
      types: [],
      gender: 'male',
      id: '',
      allerrors: '',
      types: [
        {key: 'male', value: 'Male'},
        {key: 'female', value: 'Female'},
      ],

      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.dataSlider).length === 0) {
        this.headerType = 'Add'
        this.initValues()
      }else {        
        this.id = this.dataSlider.id
        this.username = this.dataSlider.user_name
        this.first_name = this.dataSlider.first_name
        this.last_name = this.dataSlider.last_name
        this.email = this.dataSlider.email
        this.phone = this.dataSlider.phone
        this.address = this.dataSlider.address
        this.gender = this.dataSlider.gender
        this.headerType = 'Edit'
        // this.repareType(this.dataSlider.type)
        // this.initValues()
      }
    }
  },
  methods: {
    initValues() {
      if(this.dataSlider.id) return
        this.id = null
        this.username = null
        this.first_name = null
        this.last_name = null
        this.email = null
        this.phone = null
        this.captains_image = null
        this.imageUrl = null
        this.address = null
        this.gender = 'male'
        this.allerrors = {}
    },
    onSubmit(){
      if(!this.id){
        this.sendData()
      }else{
        this.editData();
      }
    },
    prepareData(){
        const data = new FormData();
        data.append('user_name', this.username);
        data.append('first_name', this.first_name);
        data.append('last_name', this.last_name);
        data.append('email', this.email);
        data.append('address', this.address);
        data.append('phone', this.phone);
        data.append('gender', this.gender);
        data.append('captains_image', this.captains_image);
        (this.id)? data.append('_method', 'put') : ''
        return data;
      
    },
    sendData(){
      this.loading = true;
        DataService.create(this.prepareData())
        .then(response => {
          this.loading = false
          
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$parent.refreshData(response.data.data);
            // this.$emit('refreshData('+response.data.data+')');
            
            this.$emit('update:is-add-new-user-sidebar-active', false)
          }else if(responseCode === 101){
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }

          let message = Error

          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    editData(){
      this.loading = true;
        DataService.update(this.id,this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$parent.refreshData(response.data.data);
            // this.$emit('refreshData('+response.data.data+')');
            
            this.$emit('update:is-add-new-user-sidebar-active', false)
          }else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }

          let message = Error

          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    onImageChange(e) {
      const file = e.target.files[0];
      this.captains_image = file
      this.imageUrl = URL.createObjectURL(file);
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    repareType(type){
      let list=[];
      type.forEach(function(value, key) {
        list.push(value.type);
      });
      return list
    }
    

  },
  setup(props, { emit }) {
    const name = ref(null)
    const name_ar = ref(null)
    let loading = ref(false)
    const allerrors = ref(null)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
       avatarText,

      //  ? Demo - Update Image on click of update button
      loading,
      name,
      name_ar,
      allerrors
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
