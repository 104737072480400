import axios from '@/libs/axios'

class DataService {
    getAll(page) {
        return axios.get("/captains?page="+page);
    }
    get(id) {
        return axios.get(`/captains/${id}`);
    }
    create(data) {
        return axios.post("/captains", data);
    }
    update(id, data) {
        return axios.post(`/captains/${id}`, data);
    }
    delete(id) {
        return axios.delete(`/captains/${id}`);
    }
    deleteAll() {
        return axios.delete(`/captains`);
    }
    findByTitle(title) {
        return axios.get(`/captains?title=${title}`);
    }
    changeStatus(id) {
        return axios.get(`/changecaptainsStatus/${id}`)
    }
}
export default new DataService();